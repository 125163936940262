<template>
  <!--begin::Charts Widget 1-->
  <div class="card" :class="widgetClasses">
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Chart-->
      <apexchart
        type="bar"
        id="chart-movement"
        :options="options"
        :series="series"
        @dataPointSelection="clickHandler"
      ></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Charts Widget 1-->
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApexCharts from "apexcharts";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import CryptoJS from "crypto-js";

export default defineComponent({
  name: "Grafik-Suara",
  props: {
    widgetClasses: String,
  },
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");

    const store = useStore();
    store.dispatch("AllMovementDashboard", { type: "candidate" });
    store.commit("SET_ACTIVE_MENU", "movement-map");

    const router = useRouter();
    const data = computed(() => store.state.MovementDashboardModule.all);

    watch(data, () => {
      console.log('masuk');
      if (data.value && data.value.length) {
        updateChart();
      }
    });
    const options = {
      chart: {
        fontFamily: "inherit",
        id: "chart-movement",
        type: "bar",
        // height: data.value.length * 5 + "px",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: ["20%"],
          // endingShape: "rounded",
          // distributed: true,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      responsive: [
        {
          breakpoint: undefined,
          options: {},
        },
      ],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + " Kali";
        },
        offsetX: 40,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        opacity: 1,
        colors: ["#226c46"],
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val + " Kali";
          },
        },
      },
      // colors: ["#D82028", "#1E1E9B", "#226c46"],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    const series = [
      {
        name: "Data",
        data: [],
      },
    ];

    // const data = ref([
    //   {
    //     label: "Caleg 1",
    //     nilai: 7,
    //   },
    //   {
    //     label: "Caleg 2",
    //     nilai: 5,
    //   },
    //   {
    //     label: "Caleg 3",
    //     nilai: 4,
    //   },
    // ]);

    const updateChart = () => {
      const dummy = ref({
        name: [],
        data: [],
      });

      if (data.value) {
        dummy.value.name = data.value.map((item) => {
          return item.name;
        });
        dummy.value.data = data.value.map((item) => {
          return item.count;
        });
      }
      const categories = Object.values(dummy.value.name);

      const nilai = Object.values(dummy.value.data);

      ApexCharts.exec("chart-movement", "updateOptions", {
        xaxis: {
          categories: categories,
        },
      });
      ApexCharts.exec(
        "chart-movement",
        "updateSeries",
        [
          {
            data: nilai,
          },
        ],
        true
      );
    };

    const clickHandler = (event, chartContext, config) => {
      const index = config.dataPointIndex;
      const id = CryptoJS.AES.encrypt(
        data.value[index].candidate_id.toString(),
        "PKS.id"
      ).toString();
      router.push({ name: "movement-map.detail", params: { id: id } });
    };

    return {
      options,
      series,
      clickHandler,
    };
  },
});
</script>
